.dashboard {
  display: flex;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.dashboardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;
  /* margin-top:70px; */
  /* max-height: 80vh; */
}
.alert {
  /* height:70px; */
  width: 70%;
  /* background:red; */
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
}

.deleted {
  background: red;
}
.created {
  background: rgba(52, 52, 253, 0.979);
}
.message {
  margin: 0px;
  /* background:red; */
  /* width: 100%; */
  /* border-radius: 3px; */
}

.shopsContainer {
  width: 80%;
}

.shopCard {
  background-color: black;
  min-height: 250px;
  /* width:80% */
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.leftCardContent {
  width: 24%;
}

.logoContainer {
  background: white;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  display: flex;
  margin: auto;
  align-items: center;
  border: 4px solid #3fadaa;
  overflow: hidden;
  justify-content: space-between;
}
.leftCardContent img {
  width: 80%;
  margin: auto;
}
.buttonContainer {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.middleCardContent {
  width: 50%;
  border-left: 1px solid white;
  border-right: 1px solid white;

  /* height:80%; */
}

.rightCardContent {
  width: 24%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightCardContent h5 {
  margin: 0;
}
.rightCardContent h6 {
  margin: 10px 0;
}

@media only screen and (max-width: 1000px) {
  .shopsContainer {
    width: 98%;
    min-height: 300px;
  }
  .dashboardContent {
    width: 98%;
    margin: auto;
  }
  .shopContainer {
    width: 98%;

  }
}

@media only screen and (max-width: 900px) {
  .dashboard {
    flex-direction: column;
  }
  .alert {
    display: none;
  }
}
