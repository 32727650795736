.shopContainer{
width:80%
}

.shopProductsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    /* align-items: center; */
}


@media only screen and (max-width: 1000px) {
    .shopContainer {
      width: 98%;
  
    }
  }
  